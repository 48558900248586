

body {
  font-family: 'Open Sans', sans-serif
}
h3 {
  margin-top:14px !important
}
  .container {
    width: 100%;
    padding-right: 14px;
    padding-left: 14px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1360px; } }

header {
  position: relative;
  padding: 20px 0; }
  header .kw-logo {
    width: 100%; }
    header .kw-logo img {
      width: 100%; }
  header .kw-menu ul {
    float: right;
    margin: 0; }
    header .kw-menu ul li:not(:last-child) {
      margin-right: 20px; }
    header .kw-menu ul li a {
      font-size: 14px;
      color: #3c4053; }
      header .kw-menu ul li a:hover {
        color: #3c4053;
        text-decoration: none; }

.btn-border {
  border: 2px solid #229490;
  padding: 10px 20px;
  border-radius: 60px;
  text-transform: uppercase;
  color: #229490 !important;
  font-weight: 600; }

.kw-wrapper {
  position: relative;
  background-image: url(/assets/images/curve-bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top left; }

  .kw-wrapper .kw-title {
    margin-top: 5px; }
    .kw-wrapper .kw-title h1 {
      font-size: 45px;
      font-weight: 700;
      color: #ffffff; }
    .kw-wrapper .kw-title p {
      font-size: 25px;
      color: #ffffff; }

  .kw-wrapper .kw-chat-wrap {
    max-width: 100%;
    margin-top: 50px; }
    .kw-wrapper .kw-chat-wrap .kw-chat-text {
      width: 580px;
      height: 199px;
      background-image: url(/assets/images/comment.png);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      float: left;
      padding: 36px 87px 36px 36px
     }
      .kw-wrapper .kw-chat-wrap .kw-chat-text h6 {
        font-size: 24px;
        font-weight: 600;
        font-style: italic;
        color: #3f4255; }
      .kw-wrapper .kw-chat-wrap .kw-chat-text p {
        font-size: 16px;
        font-weight: 600;
        font-style: italic;
        line-height: 30px;
        color: #3f4255; }
    .kw-wrapper .kw-chat-wrap .kw-bear-img {
      width: 200px;
      float: left;
      margin-left: 20px; }
      .kw-wrapper .kw-chat-wrap .kw-bear-img img {
        width: 100%; }
  .kw-wrapper .kw-bottom-text {
    margin-top: 0px; }
    .kw-wrapper .kw-bottom-text h2 {
      font-size: 24px;
      font-weight: 700;
      color: #383c4f; }
    .kw-wrapper .kw-bottom-text p {
      font-size: 16px;
      line-height: 30px;
      color: #767676; }
    .kw-wrapper .kw-device-img {
         margin-top: 5px;
    background-size: cover;
    height: 909px;
    background-repeat: no-repeat;
    background-image: url(/assets/images/mobile-device.png);
    position: relative;
    width: 409px;
    }
    .kw-wrapper .kw-device-img .bot_content {
      background-color: white;
      width: 376px;
    margin: 0 auto;
    position: relative;
    top: 100px;
    left: 0px;
    height: 655px;
   }


footer {
  background: #3f4255;
  padding: 30px 0; }
  footer p {
    font-size: 14px;
    color: #ffffff;
    margin: 0;
    font-weight: 300; }
    footer p a {
      color: #ffffff;
      font-weight: 700; }
      footer p a:hover {
        color: #ffffff;
        text-decoration: none; }

        .kw-menu {
            margin-top: 10px;
        }
        .m-signature-pad {
          position: relative;
          font-size: 10px;
          width: 500px;
          height: 400px;
          top: 50%;
          left: 50%;
          border: 1px solid #e8e8e8;
          background-color: #fff;
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
          border-radius: 4px;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }

        .m-signature-pad--body {
          position: relative;
          left: 20px;
          right: 20px;
          top: 20px;
          bottom: 60px;
          border: 1px solid #f4f4f4;
        }

        .m-signature-pad--body
          canvas {
            position: relative;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
          }

        .m-signature-pad--footer {
          position: relative;
          left: 20px;
          right: 20px;
          bottom: 20px;
          height: 40px;
        }

        .m-signature-pad--footer
          .description {
            color: #C3C3C3;
            text-align: center;
            font-size: 1.2em;
            margin-top: 1.8em;
          }

        .m-signature-pad--footer
          .button {
            position: relative;
            bottom: 0;
          }

        .m-signature-pad--footer
          .button.clear {
            left: 0;
          }

        .m-signature-pad--footer
          .button.save {
            right: 0;
          }

        @media screen and (max-width: 1024px) {
          .m-signature-pad {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;
            height: auto;
            min-width: 250px;
            min-height: 140px;
            margin: 5%;
          }
          #github {
            display: none;
          }
        }

        @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
          .m-signature-pad {
            margin: 10%;
          }
        }

        @media screen and (max-height: 320px) {
          .m-signature-pad--body {
            left: 0;
            right: 0;
            top: 0;
            bottom: 32px;
          }
          .m-signature-pad--footer {
            left: 20px;
            right: 20px;
            bottom: 4px;
            height: 28px;
          }
          .m-signature-pad--footer
            .description {
              font-size: 1em;
              margin-top: 1em;
            }
        }
        .ant-select-auto-complete.ant-select .ant-input {
          height: 50px!important;
          border: 0 !important
        }
        .ant-select-auto-complete.ant-select .ant-input[disabled] {
          background-color: black;
          color:white;
          border-width:0 !important;
          text-align: center
        }
        .aant-select-auto-complete.ant-select .ant-select-selection__rendered  {
          background-color: black;
          color:white;
          border-width:0 !important;
          border-radius: 0px;
          height:32px
        }
        .ant-select-auto-complete.ant-select .ant-input:focus {
          height:45px!important;
          box-shadow: none!important
        }
        .ant-card-body {
          padding: 7px!important;
       }
       p {
         margin-top:7;
         margin-bottom:7;
         padding: 0px 0 7px 0!important;
       }
       .ant-form-item {
         margin-bottom: 14px!important
       }